<template>
  <div>
    <MallheaderVue :titlename="'订单详情'"></MallheaderVue>
    <div class="paycontent">
      <div class="paycard paycard_blue">
        <div class="address" @click="goAddress">
          <img
            class="addicon"
            :src="require('@/assets/images/mall/locationicon.png')"
          />
          <div class="addinfo">
            <div class="userinfo">
              <span class="name">{{ addressInfo.consignee }}</span
              ><span class="tel">{{ addressInfo.mobile }}</span>
            </div>
            <div class="addtxt van-multi-ellipsis--l2">
              {{ addressInfo.province_str }}{{ addressInfo.city_str
              }}{{ addressInfo.district_str }}{{ addressInfo.area }}
            </div>
          </div>
          <img
            class="arrow"
            :src="require('@/assets/images/mall/rightarrow.png')"
          />
        </div>
      </div>
      <div class="paycard marginT16" v-if="detailInfo.order_status > 1">
        <div class="address">
          <img
                  class="addicon"
                  :src="require('@/assets/images/mall/locationicon.png')"
          />
          <div class="addinfo">
            <div class="userinfo">
              <span class="name">快递单号:</span
              ><span class="tel">{{ detailInfo.shipping_code }}</span>
            </div>
            <div class="addtxt van-multi-ellipsis--l2">
              快递名称:{{ detailInfo.shipping_name }}
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="paycard marginT16"  v-if="detailInfo.order_status==0">
        <div class="address" @click="goAddress">
          <img
            class="addicon"
            :src="require('@/assets/images/mall/locationicon.png')"
          />
          <div class="choicetxt">请选择收货地址</div>
          <img
            class="arrow"
            :src="require('@/assets/images/mall/rightarrow.png')"
          />
        </div>
      </div>
      <div class="ordercard marginT16">
        <div class="goodinfo">
          <img class="goodimg" :src="detailInfo.images" @click="godetail(detailInfo.goods_id)" />
          <div class="describle">
            <div class="goodname van-multi-ellipsis--l2">
              {{ detailInfo.name }}
            </div>
            <div class="pricenum marginT16">
              <div class="price">
                价值 {{ parseFloat(detailInfo.goods_price).toFixed(2)
                }}USD
              </div>
              <div class="num">x1</div>
            </div>
            <div class="pricenum marginT30">
              <div class="price">商品手续费：</div>
              <div class="num">{{ parseFloat(detailInfo.fee_price).toFixed(2)
                }}USD</div>
            </div>
            <div class="pricenum marginT30">
              <div class="price">运费：</div>
              <div class="num">
                {{ parseFloat(detailInfo.shipping_price).toFixed(2)
                }}USD
              </div>
            </div>
            <div class="pricenum marginT30">
              <div class="price">商品总计：</div>
              <div class="num">
                {{ parseFloat(detailInfo.total_amount).toFixed(2)
                }}USD
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom" v-if="detailInfo.order_status==0&& address.toLowerCase()==detailInfo.address.toLowerCase()">
      <div class="payinfo">
        <div class="payprice">
          {{ parseFloat(pay_num).toFixed(4) }}<span class="smalltxt">/{{ detailInfo.pay_name }}</span>
        </div>
        <div class="paybtn" @click="toPaySubmit()">支付</div>
        <!--@click="showpwd=true"-->
      </div>
    </div>
    <van-dialog v-model="showpwd" title="去支付" @cancel="pwdShow" @confirm="clickpay" show-cancel-button>
      <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
    </van-dialog>
  </div>
</template>

<script>
import metamaskConfig from "../../components/connection.js";
import { ethers } from "../../components/ethers-5.1.esm.min.js";
import { orderDetail, payOrder } from "@/request/shopapi";
import MallheaderVue from "../../components/mallheader.vue";
import { Toast } from "vant";
let defaultAccount, networkVersion;
export default {
  components: { MallheaderVue },
  data() {
    return {
      ispost: true,
      orderid: "",
      detailInfo: {},
      addressInfo: {
        consignee: "",
        mobile: "",
        province_str: "",
        city_str: "",
        district_str: "",
        area: "",
        province: "",
        city: "",
        district: "",
        address_id: "",

      },
        showpwd: false,
        password: '',
        address:'',
        userInfo:{},
        arr_price:0,
        ept_price:0,
        att_price:0,
        pay_num:0,
        pay_type:0,
        eptBanner:0,
        arrBanner:0,
        attBanner:0,
        usdtBanner:0,
        tokenDisable:false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/mall/addressedit") {
        vm.ispost = false;
      } else {
        vm.ispost = true;
      }
    });
  },
  activated() {
      this.checkLogin();

  },
  created() {
    this.$eventBus.$on("uploadAddress", (newaddress) => {
      this.addressInfo = {
        consignee: newaddress.consignee,
        mobile: newaddress.mobile,
        province_str: newaddress.province_str,
        city_str: newaddress.city_str,
        district_str: newaddress.district_str,
        area: newaddress.area,
        province: newaddress.province,
        city: newaddress.city,
        district: newaddress.district,
        address_id: newaddress.id,
      };
    });
  },
  methods: {
      async toPaySubmit(){
          var _this = this;
          var toggle = event.currentTarget;
          if(!this.address){
              Toast('请使用币安智能链链接');
              return false;
          }
          if(!this.addressInfo.address_id){
              Toast('请选择收货地址');
              return false;
          }
          let etherNum = ethers.utils.parseEther (this.detailInfo.total_amount.toString());
          let timestamp = parseInt(new Date().getTime()/1000);
        /*  sessionStorage.setItem("pay_type", timestamp);
          console.log(timestamp)
          return false;*/
          // 判断余额
          const gasPrice = await metamaskConfig.provider.getGasPrice();
          if(this.pay_type==1){
              if(parseFloat(this.pay_num)>parseFloat(this.eptBanner)){
                  Toast('钱包余额不足')
                  return false;
              }
              if(_this.tokenDisable){
                  return false;
              }
              _this.tokenDisable = true;
              await metamaskConfig.eptContract.allowance(this.address,contractConfig.orderAddress).then(async res =>{
                  if(parseFloat(ethers.utils.formatEther(res))<=0){
                      await metamaskConfig.eptContract.approve(contractConfig.orderAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                          gasPrice: gasPrice,
                          gasLimit:250000,
                      });
                  }
                  let tx = await metamaskConfig.orderContract.ATTRecharge(this.pay_type,etherNum,this.orderid,this.addressInfo.address_id,{
                      gasPrice: gasPrice,
                      gasLimit:400000,
                  })
                  if(tx){
                      Toast('The operation is successful, please be patient and wait for the block confirmation verification, which is expected to take 2-5 minutes. Please do not make repeated payments. The countdown is over. Please pull down the order to refresh..\n' +
                          '操作成功，请耐心等待区块确认验证，预期2-5分钟. 请勿重复兑付，倒计时结束，请下拉订单刷新.');
                      sessionStorage.setItem("pay_type", timestamp);
                      _this.tokenDisable = false;
                      _this.$router.push({
                          path: "/mall/myorderlist",
                      });

                  }
              }).catch(err => {
                  Toast('操作失败')
                  _this.tokenDisable = false;
                  return false;
              });
          }
          else if(this.pay_type==2){
              if(parseFloat(this.pay_num)>parseFloat(this.arrBanner)){
                  Toast('钱包余额不足')
                  return false;
              }
              if(_this.tokenDisable){
                  return false;
              }
              _this.tokenDisable = true;
              await metamaskConfig.arrContract.allowance(this.address,contractConfig.orderAddress).then(async res =>{
                  if(parseFloat(ethers.utils.formatEther(res))<=0){
                      await metamaskConfig.arrContract.approve(contractConfig.orderAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                          gasPrice: gasPrice,
                          gasLimit:250000,
                      });
                  }
                  let tx = await metamaskConfig.orderContract.ATTRecharge(this.pay_type,etherNum,this.orderid,this.addressInfo.address_id,{
                      gasPrice: gasPrice,
                      gasLimit:400000,
                  })
                  if(tx){
                      Toast('The operation is successful, please be patient and wait for the block confirmation verification, which is expected to take 2-5 minutes. Please do not make repeated payments. The countdown is over. Please pull down the order to refresh..\n' +
                          '操作成功，请耐心等待区块确认验证，预期2-5分钟. 请勿重复兑付，倒计时结束，请下拉订单刷新.');
                      sessionStorage.setItem("pay_type", timestamp);
                      _this.tokenDisable = false;
                      _this.$router.push({
                          path: "/mall/myorderlist",
                      });

                  }
              }).catch(err => {
                  Toast('操作失败')
                  _this.tokenDisable = false;
                  return false;
              });
          }
          else if(this.pay_type==4){
              if(parseFloat(this.pay_num)>parseFloat(this.usdtBanner)){
                  Toast('钱包余额不足')
                  return false;
              }
              if(_this.tokenDisable){
                  return false;
              }
              _this.tokenDisable = true;
              await metamaskConfig.usdtContract.allowance(this.address,contractConfig.orderAddress).then(async res =>{
                  if(parseFloat(ethers.utils.formatEther(res))<=0){
                      await metamaskConfig.usdtContract.approve(contractConfig.orderAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                          gasPrice: gasPrice,
                          gasLimit:250000,
                      });
                  }
                  let tx = await metamaskConfig.orderContract.ATTRecharge(this.pay_type,etherNum,this.orderid,this.addressInfo.address_id,{
                      gasPrice: gasPrice,
                      gasLimit:400000,
                  })
                  if(tx){
                      Toast('The operation is successful, please be patient and wait for the block confirmation verification, which is expected to take 2-5 minutes. Please do not make repeated payments. The countdown is over. Please pull down the order to refresh..\n' +
                          '操作成功，请耐心等待区块确认验证，预期2-5分钟. 请勿重复兑付，倒计时结束，请下拉订单刷新.');
                      sessionStorage.setItem("pay_type", timestamp);
                      _this.tokenDisable = false;
                      _this.$router.push({
                          path: "/mall/myorderlist",
                      });

                  }
              }).catch(err => {
                  Toast('操作失败')
                  _this.tokenDisable = false;
                  setTimeout(function () {
                      location.reload();
                  },3000)
                  return false;
              });
          }
          else if(this.pay_type==3){
              if(parseFloat(this.pay_num)>parseFloat(this.attBanner)){
                  Toast('钱包余额不足')
                  return false;
              }
              if(_this.tokenDisable){
                  return false;
              }
              _this.tokenDisable = true;
              await metamaskConfig.attNewContract.allowance(this.address,contractConfig.orderAddress).then(async res =>{
                  if(parseFloat(ethers.utils.formatEther(res))<=0){
                      await metamaskConfig.attNewContract.approve(contractConfig.orderAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                          gasPrice: gasPrice,
                          gasLimit:250000,
                      });
                  }
                  let tx = await metamaskConfig.orderContract.ATTRecharge(this.pay_type,etherNum,this.orderid,this.addressInfo.address_id,{
                      gasPrice: gasPrice,
                      gasLimit:1300000,
                  })
                  if(tx){
                      Toast('The operation is successful, please be patient and wait for the block confirmation verification, which is expected to take 2-5 minutes. Please do not make repeated payments. The countdown is over. Please pull down the order to refresh..\n' +
                          '操作成功，请耐心等待区块确认验证，预期2-5分钟. 请勿重复兑付，倒计时结束，请下拉订单刷新.');
                      sessionStorage.setItem("pay_type", timestamp);
                      _this.tokenDisable = false;
                      _this.$router.push({
                          path: "/mall/myorderlist",
                      });

                  }
              }).catch(err => {
                  Toast('操作失败')
                  _this.tokenDisable = false;
                  setTimeout(function () {
                      location.reload();
                  },3000)
                  return false;
              });
          }
      },
      async getBanner(){
          let _this = this;
          await metamaskConfig.eptContract.balanceOf(this.address).then( re => {
              _this.eptBanner = ethers.utils.formatEther(re)
          }).catch(err => {
              console.log(err);
              return;
          });
          await metamaskConfig.arrContract.balanceOf(this.address).then( re => {
              _this.arrBanner = ethers.utils.formatEther(re)
          }).catch(err => {
              console.log(err);
              return;
          });
          await metamaskConfig.usdtContract.balanceOf(this.address).then( re => {
              _this.usdtBanner = ethers.utils.formatEther(re)
          }).catch(err => {
              console.log(err);
              return;
          });
          await metamaskConfig.attNewContract.balanceOf(this.address).then( re => {
              _this.attBanner = ethers.utils.formatEther(re)
          }).catch(err => {
              console.log(err);
              return;
          });
      },
      async checkLogin() {
          try {
              if (metamaskConfig.isMetamaskConnected) {
                  ethereum.autoRefreshOnNetworkChange = false;
                  networkVersion = await metamaskConfig.getNetworkId();
                  if (networkVersion == 1) {
                      //以太坊主网
                      console.log("ETH");
                      Toast("请使用币安智能链链接");
                      return false;
                  } else if (networkVersion == 42) {
                      //以太坊测试网  K
                      console.log("ETH");
                      Toast("请使用币安智能链链接");
                      return false;
                  } else if (networkVersion == 128) {
                      //HECO主网
                      console.log("HT");
                      Toast("请使用币安智能链链接");
                      return false;
                  } else if (networkVersion == 256) {
                      //HECO测试网
                      console.log("HT");
                      Toast("请使用币安智能链链接");
                      return false;
                  } else if (networkVersion == 56) {
                      //币安主网
                      console.log("BNB");
                  } else {
                      console.log("其他");
                      Toast("请使用币安智能链链接");
                      return false;
                  }
                  defaultAccount = await metamaskConfig.connectToAccount();
                  console.log("address connected:", defaultAccount);
                  if (defaultAccount[0]) {
                      this.address = defaultAccount[0];
                      if(this.address &&sessionStorage.getItem(this.address)){
                          await  this.getPrice();
                          this.getBanner();
                          this.address = sessionStorage.getItem("address");
                          this.userInfo = sessionStorage.getItem("user")
                              ? JSON.parse(sessionStorage.getItem("user"))
                              : {};
                          if (this.ispost) {
                              this.orderid = this.$route.query.id;
                              this.requestorderDetail();
                          }
                      }
                  }
              } else {
                  Toast("请使用币安智能链链接");
                  console.log("Connect to available ethereum network!");
              }
          } catch (e) {
              this.$refs.regsheet.preview();
              console.log("1111");
          }
      },
      async getPrice(){
          let _this = this;
          await metamaskConfig.orderContract.getPrice(1).then( re => {
              _this.ept_price = ethers.utils.formatEther(re)
              // console.log(  _this.ept_price)
          }).catch(err => {
              console.log(err);
              return;
          });
          await metamaskConfig.orderContract.getPrice(2).then( re => {
              _this.arr_price = ethers.utils.formatEther(re)
              // console.log(  _this.arr_price)
          }).catch(err => {
              console.log(err);
              return;
          });
          await metamaskConfig.orderContract.getPrice(3).then( re => {
              _this.att_price = ethers.utils.formatEther(re)
              // console.log(  _this.att_price)
          }).catch(err => {
              console.log(err);
              return;
          });
      },
      godetail(id) {
          this.$router.push({
              path: "/mall/goodsdetail",
              query: {
                  id: id,
              },
          });
      },
      pwdShow() {
          this.showpwd = false;
      },
    requestorderDetail() {
      orderDetail({ id: this.orderid }).then((res) => {
        this.detailInfo = res.data;
        if(this.detailInfo.pay_name=='EPT'){
            this.pay_num = parseFloat(this.detailInfo.total_amount)/parseFloat(this.ept_price);
            this.pay_type = 1;
        }else if(this.detailInfo.pay_name=='ARR'){
            this.pay_num = parseFloat(this.detailInfo.total_amount)/parseFloat(this.arr_price);
            this.pay_type = 2;
        }
        else if(this.detailInfo.pay_name=='ATT'){
            this.pay_num = parseFloat(this.detailInfo.total_amount)/parseFloat(this.att_price);
            this.pay_type = 3;
         }
        else if(this.detailInfo.pay_name=='USDT'){
            this.pay_num = parseFloat(this.detailInfo.total_amount);
            this.pay_type = 4;
        }
        console.log(this.pay_num)
        this.addressInfo = {
          consignee: this.detailInfo.consignee,
          mobile: this.detailInfo.mobile,
          province_str: this.detailInfo.province_str,
          city_str: this.detailInfo.city_str,
          district_str: this.detailInfo.district_str,
          area: this.detailInfo.area,
          province: this.detailInfo.province,
          city: this.detailInfo.city,
          district: this.detailInfo.district,
          address_id: this.detailInfo.address_id,
        };
      });
    },
    goAddress() {
      this.$router.push({
        path: "/mall/addressedit",
      });
    },
    requestpayOrder() {
      const params = {
        address: this.address,
        pay_code: this.password,
        order_id: this.orderid,
        address_id: this.addressInfo.address_id,
      };
      payOrder(params).then((res) => {
        let success = res.success;
        if (success) {
          Toast("支付成功");
          this.$router.go(-1);
        } else {
          Toast(res.msg);
        }
      });
    },
    clickpay() {
        let _this = this;
        if (!this.password) {
            Toast('请输入密码');
            return
        }
        if(!this.address){
            Toast('请使用币安智能链链接')
            return
        }
        if(this.address&&this.userInfo.has_paypwd!=1){
            Toast('请先完善资料,设置安全密码');
            setTimeout(function () {
                _this.$router.push({
                    path:'/home/mine'
                })
            })
            return
        }
      this.requestpayOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
.marginT16 {
  margin-top: 16px;
}
.marginT30 {
  margin-top: 30px;
}
.paycontent {
  background: #ebeef2;
  padding: 0 24px;
  height: 100vh;
  .paycard {
    width: 702px;
    height: 168px;
    border-radius: 8px;
    opacity: 1;
    border: 2px dashed rgba(189, 41, 223, 1);
    background: rgba(255, 255, 255, 1);
    padding: 24px;
    display: flex;
    align-items: center;
    .address {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .addicon {
        width: 64px;
        height: 64px;
      }
      .addinfo {
        width: 472px;
        .userinfo {
          font-size: 24px;
          .name {
            color: rgba(41, 33, 29, 1);
            font-size: 28px;
            font-weight: 700;
            font-family: "PingFang SC";
            margin-right: 8px;
          }
          .tel {
            color: rgba(105, 98, 95, 1);
            font-size: 24px;
            font-weight: 500;
            font-family: "DIN";
          }
        }
        .addtxt {
          margin-top: 8px;
          color: rgba(105, 98, 95, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
      }
      .arrow {
        width: 32px;
        height: 32px;
        opacity: 1;
      }
    }
    .choicetxt {
      width: 472px;
      text-align: left;
      opacity: 1;
      color: rgba(41, 33, 29, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
  .paycard_blue{
    border: 2px dashed #3FAD8D;
  }
  .ordercard {
    width: 702px;
    height: 350px;
    border-radius: 24px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    padding: 24px;
    .goodinfo {
      display: flex;
      .goodimg {
        width: 112px;
        height: 112px;
        background-color: orange;
        margin-right: 16px;
      }
      .describle {
        width: 526px;
        .goodname {
          color: rgba(41, 33, 29, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
        .pricenum {
          display: flex;
          justify-content: space-between;
          .price {
            color: rgba(105, 98, 95, 1);
            font-size: 24px;
            font-weight: 400;
            text-align: left;
            line-height: 36px;
            font-family: "PingFang SC";
          }
          .num {
            opacity: 1;
            color: rgba(102, 102, 102, 1);
            font-size: 24px;
            font-weight: 500;
            font-family: "DIN";
            text-align: right;
          }
        }
      }
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0px;
  width: 750px;
  height: 112px;
  opacity: 1;
  background: rgba(250, 247, 245, 1);
  box-shadow: 0 -8px 8px 0 rgba(80, 29, 0, 0.12);
  padding: 16px 30px;
  display: flex;
  justify-content: flex-end;
  .payinfo {
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 440px;
    height: 80px;
    border-radius: 24px 74px 74px 24px;
    opacity: 1;
    border: 2px solid rgba(241, 193, 193, 1);
    background: rgba(250, 240, 240, 1);
    .payprice {
      color: rgba(241, 27, 27, 1);
      font-weight: 500;
      text-align: left;
      font-size: 36px;
      font-family: "DIN";
      line-height: 44px;
      .smalltxt {
        color: rgba(241, 27, 27, 1);
        font-weight: 400;
        text-align: left;
        font-size: 24px;
        font-family: "PingFang SC";
        line-height: 36px;
      }
    }
    .paybtn {
      width: 220px;
      height: 80px;
      border-radius: 74px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      color: rgba(238, 238, 238, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 80px;
    }
  }
}
</style>
